import React from 'react';
import { AuthProvider } from './AuthContext';
import AppRoutes from './components/AppRoutes'; // Your component that contains Routes

function App() {
  return (
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
  );
}

export default App;
