import React, { useState } from 'react';
import '../css/Dashboard.css'; // Assuming your CSS file is named Dashboard.css
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import JSTConfigComponent from './JSTConfigComponent';

const Dashboard = () => {
  const { cleanToken } = useAuth();
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState('JSTConfig'); // 用于跟踪当前选中的功能

  const doLogout = () => {
    cleanToken();
    navigate('/login');
  };

  return (
    <div className="dashboard-container">
      <aside className="sidebar">
        <div className="logo">LOGO</div>
        <ul className="menu">
          <li>
            <button onClick={() => setActiveComponent('JSTConfig')}>
              JST CONFIG
            </button>
          </li>
          <li>
            <button onClick={() => setActiveComponent('Feature2')}>
              功能2
            </button>
          </li>
          <li>
            <button onClick={() => setActiveComponent('Feature3')}>
              功能3
            </button>
          </li>
        </ul>
      </aside>
      <section className="content">
        <header className="header">
          <span className="username">Username</span>
          <button className="logout" onClick={doLogout}>
            Logout
          </button>
        </header>
        <main className="main-content">
          {activeComponent === 'JSTConfig' && <JSTConfigComponent />}
          {/* 根据 activeComponent 的值渲染对应的组件 */}
          {/* {activeComponent === 'Feature2' && <Feature2Component />} */}
          {/* {activeComponent === 'Feature3' && <Feature3Component />} */}
        </main>{' '}
      </section>
    </div>
  );
};

export default Dashboard;
